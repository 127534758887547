export function isMobile() {
  var userAgent = navigator.userAgent || navigator.vendor;

  // 检查 Android 设备，如果包含 "Mobile" 字样，则是手机
  if (/android/i.test(userAgent) && /mobile/i.test(userAgent)) {
    return true; // 安卓手机
  }

  // 排除 iPad
  if (/iPad/.test(userAgent)) {
    return false; // iPad
  }

  // 检查 iPhone 和 iPod
  if (/iPhone|iPod/.test(userAgent)) {
    return true; // iPhone 和 iPod
  }

  // 检查 Windows Phone
  if (/Windows Phone/i.test(userAgent)) {
    return true; // Windows Phone
  }

  // 检查 BlackBerry 设备
  if (/BB10|BlackBerry|PlayBook/i.test(userAgent)) {
    return true; // BlackBerry 设备
  }

  return false; // 其他设备（包括安卓平板）
}

export function isIPad() {
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const isIPadScreenSize = (screen.width >= 768 && screen.width <= 834) && (screen.height >= 1024);
  return isIOS && isIPadScreenSize;
}