import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
// import Home from '../views/Home/index.vue'
import Home from '../views/Index/index.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    // component: Home
    component: Home
  },
  {
    path: '/detail-rule',
    name: 'DetailRule',
    component: () => import(/* webpackChunkName: "detail" */ '../views/DetailRule/index.vue')
  },
  {
    path: '/rule',
    name: 'Rule',
    component: () => import(/* webpackChunkName: "rule" */ '../views/Rule/index.vue')
  },
  {
    path: '/activity',
    name: 'Activity',
    component: () => import(/* webpackChunkName: "activity" */ '../views/Activity/index.vue')
  },
  {
    path: '/group',
    name: 'Group',
    component: () => import(/* webpackChunkName: "group" */ '../views/Group/index.vue')
  },
  {
    path: '/success',
    name: 'Success',
    component: () => import(/* webpackChunkName: "success" */ '../views/Success/index.vue')
  },
  {
    path: '/attend',
    name: 'Attend',
    component: () => import(/* webpackChunkName: "attend" */ '../views/Attend/index.vue')
  },
  // {
  //   path: '/join',
  //   name: 'Join',
  //   component: () => import(/* webpackChunkName: "join" */ '../views/Join/index.vue')
  // },
  // {
  //   path: '/attend-result',
  //   name: 'AttendResult',
  //   component: () => import(/* webpackChunkName: "attend-result" */ '../views/Result/index.vue')
  // },
]

const router = createRouter({
  history: createWebHashHistory(),
  // mode: '',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

router.beforeEach(async(to, from, next) => {
  next()
})

export default router
