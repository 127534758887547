import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

// 创建 Axios 实例
const instance: AxiosInstance = axios.create({
  // baseURL: process.env.VUE_APP_API_BASE_URL,
  // baseURL: 'http://portal.singthewhampoa.com:3000',
  // baseURL: 'http://47.99.63.87:3000',
  // baseURL: 'https://eowxofc3j56ow12.m.pipedream.net',
  // baseURL: 'http://localhost:1337/api',
  // baseURL: 'https://dev-strapi.plugcustom.com.cn/api',
  baseURL: 'https://strapi.plugcustom.com.cn/api',
  timeout: 15000,
  headers: {
    // Authorization: 'Bearer sk_test_51QN36OLKgnK8zqWywKFl1gerp7akkJLWuku3dzVUZvPhU3DxyNOEOpEs8BXyRlwAoVx4DW0qv8LELmlFRXaseoUi00Cs2DYWhH'
  }
});

// 用户认证 token
let authToken: string | null = null;
let userId: string | null = null;

// 添加认证 token 到请求头
function setAuthHeader(config: AxiosRequestConfig): AxiosRequestConfig {
  return {
    
    ...(config || null)
  };
}


// 监听响应，处理 token 失效并重新请求
instance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async (error) => {
    const originalRequest: any = error.config;
    if (error.response.status === 403 && !originalRequest._retry) {
      
    }
    return Promise.reject(error);
  }
);

// 发起 GET 请求
export async function get<T>(url: string, config: AxiosRequestConfig = {}): Promise<T> {
  try {
    const response: AxiosResponse<T> = await instance.get(url, setAuthHeader(config));
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

// 发起 POST 请求
export async function post<T>(url: string, data: any, config: AxiosRequestConfig = {}): Promise<T> {
  try {
    const response: AxiosResponse<T> = await instance.post(url, data, setAuthHeader(config));
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

// 获取token
export async function login(username: string, password: string): Promise<void> {
  try {
    const response: AxiosResponse = await instance.post('/api/login', { username, password });
    authToken = response.data.data?.access_token;
    console.log(response.data.data)
    userId = response.data.data?.user_id;
    // console.log('成功:', response.data.data);
  } catch (error) {
    console.error('失败:', error.response.data);
  }
}

// 使用示例
async function main(): Promise<void> {

}

main();
