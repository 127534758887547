<template>
  <div>
    <router-view/>
  </div>
</template>

<style lang="less">
#app {
  font-family: 'GenSenRounded2-B';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  color: #2c3e50;
  img {
    width: 100%;
  }
}
</style>
